@import url("https://rsms.me/inter/inter.css");
/*---------------------------- ui-core.css ------------------------------
  Base
-----------------------------------------------------------------------*/
/* LA theme */
/* 
--bg-main-h: 195;
--bg-main-s: 13%;
--bg-main-l: 6%;

--bg-nav-h: 0;
--bg-nav-s: 0%;
--bg-nav-l: 12%;

--bg-panel-h: 216;
--bg-panel-s: 9%;
--bg-panel-l: 11%;

--bg-panelAdv-h: 0;
--bg-panelAdv-s: 0%;
--bg-panelAdv-l: 12%;

--text-h: 0;
--text-s: 0%;
--text-l: 87%;

--bg-modal: hsl(0, 0%, 19%);
*/

:root {
  --bg-main-h: 0;
  --bg-main-s: 0%;
  --bg-main-l: 8%;
  --bg-main: hsl(
    var(--bg-main-h), 
    var(--bg-main-s), 
    var(--bg-main-l)
  );

  --bg-nav-h: 0;
  --bg-nav-s: 0%;
  --bg-nav-l: 12%;
  --bg-nav: hsl(
    var(--bg-nav-h), 
    var(--bg-nav-s), 
    var(--bg-nav-l)
  );

  --bg-panel-h: 216;
  --bg-panel-s: 4%;
  --bg-panel-l: 14%;
  --bg-panel: hsl(
    var(--bg-panel-h), 
    var(--bg-panel-s), 
    var(--bg-panel-l)
  );

  
  --bg-panelAdv-h: 0;
  --bg-panelAdv-s: 0%;
  --bg-panelAdv-l: 12%;
  --bg-panelAdv: hsl(
    var(--bg-panelAdv-h), 
    var(--bg-panelAdv-s), 
    var(--bg-panelAdv-l)
  );


  --bg-modal: hsl(0, 0%, 19%);
  
  --COLOR-DARK-BG: var(--bg-main);
  --COLOR-DARK-1: var(--bg-panel);

  
  --COLOR-MODAL-BG: #303030;

  --COLOR-GREY-0: #464646;
  --COLOR-GREY-1: #6a6a6a;
  --COLOR-GREY-2: #888888;

  --COLOR-BORDER-0: #252525;
  --COLOR-BORDER-1: #2e2e2e;
  --COLOR-BORDER-2: #333;

  --text-h: 0;
  --text-s: 0%;
  --text-l: 87%;
  --text: hsl(
    var(--text-h), 
    var(--text-s), 
    var(--text-l)
  );
  --COLOR-WHITE: var(--text);


  /* brand colors */
  --brandGrayDark: hsl(0, 0%, 13%);
  --gray4: hsl(195, 9%, 33%);
  --gray3: hsl(195, 4%, 60%);
  --gray2: hsl(200, 9%, 79%);
  --gray1: hsl(240, 19%, 95%);

  --orange: hsl(21, 90%, 54%);
  --orange-h: 21;
  --orange-s: 90%;
  --orange-l: 54%;
  
  /* --telosBlue: hsl(205, 100%, 35%); */
  --blue: hsl(205, 89%, 45%);
  --blue-h: 205;
  --blue-s: 89%;
  --blue-l: 45%;

  --COLOR-RED: hsl(0, 68%, 50%);
  --red-h: 0;
  --red-s: 68%;
  --red-l: 50%;


  

  --primary-h: var(--blue-h);
  --primary-s: var(--blue-s);
  --primary-l: var(--blue-l);
  --primary: hsl(
    var(--primary-h), 
    var(--primary-s), 
    var(--primary-l)
  );

  --primary-dark: hsl(
    var(--primary-h), 
    calc(var(--primary-s) - 20%),
    calc(var(--primary-l) - 10%)
  );
  
  --primary-light: hsl(
    var(--primary-h), 
    calc(var(--primary-s) - 10%),
    calc(var(--primary-l) + 32%)
  );

  --COLOR-TXT-VALUE: var(--primary-light);

  

  --font-family: "Inter", sans-serif;
  --line-height: 1.3;
  --font-size: 1rem;
  --letter-spacing: -0.02em;
}

.lightTheme .nav-link,
.lightTheme .panel-title {
  text-shadow: none !important;
}
.lightTheme .nav-link svg {
  filter: none;
}
.lightTheme .productLogo img {
  filter: invert(1);
}


.btn,
.panel,
.dropdownMenu-root,
.meter-container,
.uic-toggle,
.uic-fileInput,
.uic-snackbar,
.uic-snackbar > div,
.uic-modal {
  color: var(--text);
  font-family: var(--font-family);
  line-height: var(--line-height);
  font-size: var(--font-size);
  letter-spacing: var(--letter-spacing);
}



/*-----------------------------------------------------------------------
  Panel
-----------------------------------------------------------------------*/

.panel {
  border-radius: 4px;
  background: var(--COLOR-DARK-1);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.22),
    0px 1px 1px 0px rgba(0, 0, 0, 0.16), 0px 2px 1px -1px rgba(0, 0, 0, 0.14);
  border: 1px solid rgba(60, 60, 60, 0.11);
  border-top: 1px solid rgba(60, 60, 60, 0.3);
}

.panel-title {
  width: 100%;
  padding: 4px 10px 6px 10px !important;
  color: hsla(var(--text-h), var(--text-s), var(--text-l), 0.8);
  font-size: 24px;
  text-shadow: 0px 0px 2px #000, 
               0px 0px 8px #0008, 
               0px 0.5px 1px #000;

  background: #181818;
  background: hsl(0, 0%, calc(var(--bg-panel-l) * 0.82));

  border-radius: 3px 3px 0 0;
  /* border-bottom: 1px solid rgba(0, 0, calc(var(--bg-panel-l) / 2), 0.7); */
  border-bottom: 1px solid hsla(0, 0%, calc(var(--bg-panel-l) / 2), 0.7);
  box-shadow: 0px 1px 1.2px 0px hsl(0, 0%, calc(var(--bg-panel-l) * 1.2)),
        inset 0px 12px 23px -10px rgb(255, 255, 255, 0.015),
        0px 10px 16px -9px rgb(255, 255, 255, 0.02);
}
/* .panel-title > svg {
  vertical-align: middle;
  margin-right: 6px;
  margin-left: -3px;
  padding-bottom: 3px;
} */

.panel-body {
  padding: 12px;
}


.panel-subSection:not(:first-child) {
  padding-top: 16px;
}
.panel-subSection-header {
  margin-bottom: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid #333;
  font-weight: 500;
  color: #bbb;
}

.panel-subSection-body {
  padding: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.panel-title > .uic-toggle {
  text-shadow: none;
  float: right;
  transform: translate(-2px, 4px);
  /* vertical-align: middle;
  margin-left: 12px;
  margin-top: -2px; */
}
.panel-title .uic-toggle-input:not(:checked) + .uic-toggle-slider {
  background-color: #555;
}

/*––––––––––––––– CollapsablePanel –––––––––––––––*/

.collapsablePanel {
  composes: panel;
}
.titleContainer {
  position: relative;
  height: 42px;
  width: 100%;
  cursor: pointer;
  transition: background 90ms ease;
}
.titleContainer:hover .panel-title {
  background: #16181b;
}
.titleContainer:hover .collapseBtn {
  color: var(--COLOR-TXT-VALUE);
}

.collapsablePanel .panel-title {
  pointer-events: none;
}

svg.collapseBtn {
  pointer-events: none;
  height: 42px;
  width: 50px;
  padding: 4px;
  position: absolute;
  right: 0px;
  cursor: pointer;
  color: #888;
  transform: rotate(180deg);
  transition: transform 0.15s, color 90ms ease;
}
.collapsablePanel.isCollapsed .collapseBtn {
  transform: rotate(0deg);
}
.collapsablePanel.isCollapsed .panel-title {
  padding-top: 5px;
  border-radius: 3px;
  border-bottom: none;
  box-shadow: none
}

.collapsablePanel .panel-body {
  overflow: hidden;
  max-height: 3000px; /* needed for transition */
  transition: max-height 0.1s ease, padding 0s ease 0s;
  /* will-change: height, padding; */
}

.collapsablePanel.isCollapsed .panel-body {
  padding: 0px;
  max-height: 0px;
  transition: max-height 0.1s ease, padding 0s ease 0.1s;
}

/*––––––––––––––– SubSection –––––––––––––––*/

.panel-subSection:not(:first-child) {
  padding-top: 16px;
}
.panel-subSection-header {
  margin-bottom: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid #333;
  font-weight: 500;
  color: #bbb;
}

.panel-subSection-body {
  padding: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
}

/*––––––––––––––– PanelFooter –––––––––––––––*/

.panel-footer {
  margin: 0 -12px;
  margin-bottom: -12px;
  margin-top: 12px;
  padding: 10px 12px;
  overflow: hidden;
  background: linear-gradient(rgb(26, 27, 30) 50%, rgb(29, 29, 31));
  border-top: 1px solid rgb(255, 255, 255, 0.05);
  /* box-shadow: 0px -1px 0px 0px rgb(15, 15, 15, 0.4); */
}

/*––––––––––––––– AdvancedPanel –––––––––––––––*/
.advancedPanel {
  margin: 0 -12px;
  margin-bottom: -12px;
  margin-top: 10px;
  overflow: hidden;
  border-top: 1px solid #18181b;
}

.advancedPanel .advancedPanel-root {
  background: var(--bg-panelAdv);
  color: #fff;
  box-shadow: none;
  border-top: 1px solid rgb(38, 38, 38);
  border-radius: 0px 0px 3px 3px !important;
}

.advancedPanel .advancedPanel-btn-bar {
  min-height: 38px !important;
  height: 38px !important;
  transition: background 0.1s ease 0s;
}
.advancedPanel .advancedPanel-btn-bar:hover {
  background: #1b1b1b;
}

.advancedPanel .advancedPanel-header {
  color: #bbb;
}

.advancedPanel .advancedPanel-icon {
  color: #bbb;
}

.advancedPanel .advancedPanel-body {
  padding-top: 12px;
}

.advancedPanel > div > div:last-child {
  transition-duration: 160ms !important;
}


/*-----------------------------------------------------------------------
  Box
-----------------------------------------------------------------------*/

.uic-box {
  border-radius: 4px;
  padding: 8px;
}
.uic-box-title {
  color: #999;
  padding-left: 5px;
  padding-bottom: 6px;
  font-size: 22px;
  font-weight: 300;
}

.uic-box.underline {
  padding-top: 0px;
}
.uic-box.underline .uic-box-title {
  border-bottom: 1px solid #393939;
  padding-left: 0px;
  padding-bottom: 2px;
  margin-left: 4px;
  margin-bottom: 8px;
  margin-right: 8px;
}

.uic-box.solid {
  border-radius: 4px;
  box-shadow: 0 0 0px 0.5px #393939, 0 0 0px 1px #0006;
  /* background: #2d292f; */
  background: #2b2d2d;
}

.uic-box.solidDark {
  background: #1114;
}

.uic-box.outline {
  border: 1px solid #444;
  border-radius: 4px;
  padding: 8px;
  padding-top: 16px;
  margin-top: 7px;
}
.uic-box.outline .uic-box-title {
  position: absolute;
  transform: translate(5px, -30px);
  display: inline-block;
  padding: 0px 8px;
  border-radius: 4px;
  background: #222425;
  color: #888;
  font-weight: 300;
  font-size: 21px;
}


/*-----------------------------------------------------------------------
  VUMeter
-----------------------------------------------------------------------*/

.meter-container {
  display: inline-block;
  position: relative;
  user-select: none;
}

.meter-container > canvas {
  border-radius: 2px;
  margin-top: -2px;
}

.meter-readout {
  position: relative;
  font-size: 10px;
  height: 20px;
  margin-top: -20px;
  text-align: center;
  cursor: pointer;
}
.meter-readout:active {
  color: rgb(175, 175, 175);
}

.meter-label {
  padding-top: 2px;
  text-align: center;
  font-size: 14px;
}

.meter-targetLevelIndicator {
  position: absolute;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  z-index: 1;
  transition: top 130ms;
}
.meter-targetLevelIndicator > svg {
  position: absolute;
  height: 17px;
  width: 17px;
  top: -9px;
  color: rgba(255, 255, 255, 0.4);
  mix-blend-mode: difference;
}
.meter-targetLevelIndicator.left {
  left: -1px;
}
.meter-targetLevelIndicator.left > svg {
  left: -12px;
  /* clip-path: inset(0 0 0 7px); */
}
.meter-targetLevelIndicator.right {
  right: -1px;
}
.meter-targetLevelIndicator.right > svg {
  right: -12px;
  transform: rotate(180deg);
}

.meter-ticks {
  position: absolute;
}

.meter-ticks-ln {
  --TICK-LN-WIDTH: 5px;
  --TICK-LN-SPACE: 3px;
  position: absolute;
  width: var(--TICK-LN-WIDTH);
  left: calc(0px - var(--TICK-LN-SPACE) - var(--TICK-LN-WIDTH));
  border-top: 1px solid #888888;
}
.meter-ticks-ln.right {
  left: var(--TICK-LN-SPACE);
}

.meter-ticks-label {
  position: relative;
  height: 0px;
  font-size: 10px;
  color: #cccccc;
}
.meter-ticks-label.left {
  text-align: right;
  left: -37px;
}
.meter-ticks-label.right {
  text-align: left;
  left: 14px;
}

.meter-ticks-pt {
  position: relative;
  width: 3px;
  height: 1px;
  left: -23px;
  margin-top: -1px;
  border-top: 1px solid #555;
  z-index: -1;
}

.meter-horizontal {
  --offsetX: 0px;
}
.meter-horizontal .meter-container {
  transform: rotate(90deg) translate(var(--offsetX), 0px);
  transform-origin: bottom left;
}
.meter-horizontal .meter-readout {
  transform: rotate(-90deg) translate(2px, 4px);
}
.meter-horizontal .meter-ticks-label {
  transform: rotate(-90deg) translateX(-4px);
  left: 5px !important;
}

/*-----------------------------------------------------------------------
  Select
-----------------------------------------------------------------------*/

.uic-select-container {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
}

.uic-select-container .uic-select {
  max-width: 100%;
  height: 28px;
  padding: 2px 16px 2px 8px;
  font-size: inherit;
  border: 1px solid rgb(75, 75, 75);
  border-radius: 3px;
  background: rgb(43, 43, 43);
  color: white;
  -webkit-appearance: none;

  background: rgb(48, 48, 48);
  border: 1px solid rgb(55, 55, 55);
  border-top: 1px solid rgb(75, 75, 75);
  border-bottom: 1px solid rgb(55, 55, 55);
  box-shadow: inset 0 -3px 6px -4px rgba(0,0,0,0.5);
}

.uic-select-container .uic-select-arrows {
  position: absolute;
  right: 1px;
  top: 6px;
  width: 16px;
  height: 16px;
  font-size: 24px;
  pointer-events: none;
  fill: #ddd;
}

.uic-select-container .uic-select:disabled {
  box-shadow: inset 0 0px 4px rgb(28, 28, 31);
  background: rgb(41, 41, 43);
  color: #999;
  cursor: not-allowed;
}

body:not(.intent-mouse) .uic-select:focus {
  outline: none;
  box-shadow: 
    0 0px 0 2px hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 30%), 0.8);
    /* inset 0 0 0 1px #0006; */
}

/*-----------------------------------------------------------------------
  Slider
-----------------------------------------------------------------------*/

.uic-slider {
  display: inline-block;
  vertical-align: middle;
}

.uic-slider .MuiSlider-root {
  width: 100px;
  height: 20px;
  padding: 0px;
  color: var(--primary);
}
.uic-slider .MuiSlider-rail {
  height: 20px;
  border-radius: 1px;
}
.uic-slider .MuiSlider-track {
  height: 20px;
  border-radius: 1px 0px 0px 1px;
}
.uic-slider .MuiSlider-thumb {
  width: 2px;
  height: 20px;
  margin-top: 0px;
  margin-left: -1px;
  border-radius: 0px;
  box-shadow: 1px 0px 4px 0px rgba(0, 0, 0, 0.5);
  background-color: hsl(
      var(--primary-h), 
      var(--primary-s),
      70%
    );
}
.uic-slider .MuiSlider-thumb:hover {
  height: 22px;
  margin-top: -1px;
  box-shadow: 1px 0px 5px 0px rgba(0, 0, 0, 0.6);
  background-color: hsl(
      var(--primary-h), 
      var(--primary-s),
      80%
    );
}

.uic-slider .MuiSlider-thumb.MuiSlider-active {
  box-shadow: none;
  background-color: hsl(
      var(--primary-h), 
      var(--primary-s),
      85%
    );
}

/* showMinMax */

.uic-slider .MuiSlider-marked {
  margin-bottom: unset;
}
.uic-slider .MuiSlider-mark {
  display: none;
}
.uic-slider .MuiSlider-markLabel {
  top: 22px;
  font-size: 10px;
  color: #888 !important;
}
.uic-slider .MuiSlider-markLabel[data-index="0"] {
  transform: unset;
}
.uic-slider .MuiSlider-markLabel[data-index="1"] {
  transform: translateX(-100%);
}

/*-----------------------------------------------------------------------
  Input
-----------------------------------------------------------------------*/

.uic-input {
  max-width: 100%;
  height: 28px;
  padding: 2px 8px;
  margin: 0;
  border: 1px solid rgb(75, 75, 75);
  border-radius: 3px;
  font-size: inherit;
  background: rgb(43, 43, 43);
  color: white;
  box-shadow: inset 0 0px 5px rgb(37, 37, 37);
  box-shadow: inset 0 1px 5px rgb(37, 37, 37), inset 0px 0px 0px 1px rgb(35, 35, 35);
  border: 1px solid rgb(68, 68, 68);
}
.uic-input:disabled {
  box-shadow: inset 0 0px 4px rgb(28, 28, 31);
  background: rgb(41, 41, 43);
  color: #999;
  cursor: not-allowed;
}

input[type="number"].uic-input {
  padding: 2px 8px;
}
/* input[type="number"]::-webkit-outer-spin-button, */
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
  margin-right: -4px;
  transition: opacity 90ms;
  filter: invert(100%) contrast(50%) brightness(140%);
}

.uic-input:focus {
  outline: none;
  box-shadow: 
    0 0px 0 2px hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 30%), 0.5);
}

/*-----------------------------------------------------------------------
  Toggle
-----------------------------------------------------------------------*/

.uic-toggle.showText {
  --toggleScale: 1.15;
  --width: 52px;
  font-size: 14px;
  font-weight: 600;
}

.uic-toggle {
  --toggleScale: 1;
  --width: 37px;

  position: relative;
  display: inline-block;
  width: calc(var(--width) * var(--toggleScale));
  height: calc(22px * var(--toggleScale));
}
.uic-toggle * {
  cursor: pointer;
}

.uic-toggle-input {
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;
}

.uic-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: calc(12px * var(--toggleScale));
  background-color: #777;
  transition: 0.12s;
}

.uic-toggle-slider:before {
  position: absolute;
  content: "";
  height: calc(18px * var(--toggleScale));
  width: calc(18px * var(--toggleScale));
  left: calc(2px * var(--toggleScale));
  bottom: calc(2px * var(--toggleScale));
  border-radius: 50%;
  background-color: white;
  filter: drop-shadow(2px 5px 7px rgba(0, 0, 0, 0.7));
  transition: 0.12s;
}

.uic-toggle-input:active + .uic-toggle-slider:before {
  background-color: #aaa;
  filter: drop-shadow(1px 4px 6px rgba(0, 0, 0, 1));
}


.uic-toggle-input:checked + .uic-toggle-slider {
  background-color: var(--primary);
}
.uic-toggle-input:checked + .uic-toggle-slider:before {
  transform: translateX(calc((var(--width) - 22px) * var(--toggleScale)));
}

.uic-toggle-input:disabled + .uic-toggle-slider {
  cursor: not-allowed;
}
.uic-toggle-input:disabled + .uic-toggle-slider:before {
  background-color: #999;
  filter: drop-shadow(1px 4px 6px rgba(0, 0, 0, 1));
}
.uic-toggle-input:disabled:checked + .uic-toggle-slider {
  background-color: #1181b6;
}

body:not(.intent-mouse) .uic-toggle-input:focus + .uic-toggle-slider {
  box-shadow: 0 0px 0 2px hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 30%), 0.8);
}

/*--- showText stuff ---*/
.uic-toggle.showText .uic-toggle-sliderText:after {
  content: "OFF";
  position: absolute;
  top: 4px;
  right: 6px;
  background-color: transparent;
  color: #eee;
  opacity: 1;
  transition: opacity 0.12s;
}
.uic-toggle.showText
  .uic-toggle-input:checked
  + .uic-toggle-slider
  + .uic-toggle-sliderText:after {
  opacity: 0;
}
.uic-toggle.showText .uic-toggle-sliderText:before {
  content: "ON";
  position: absolute;

  top: 4px;
  left: 7px;
  background-color: transparent;
  color: #eee;
  opacity: 0;
  transition: opacity 0.12s;
}
.uic-toggle.showText
  .uic-toggle-input:checked
  + .uic-toggle-slider
  + .uic-toggle-sliderText:before {
  opacity: 1;
}

/*-----------------------------------------------------------------------
  Toggle Buttons
-----------------------------------------------------------------------*/

.uic-toggleBtns.MuiToggleButtonGroup-root {
  background-color: rgb(35, 35, 35);
  border: 1px solid rgba(0, 0, 0, 0.5);
  /* vertical-align: middle; */
}
/* .uic-toggleBtns .MuiToggleButton-sizeSmall {
} */
.uic-toggleBtns .MuiToggleButton-root {
  height: 36px;
  font-family: var(--font-family);
  text-transform: none;
  letter-spacing: unset;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.uic-toggleBtns .MuiToggleButton-root.Mui-selected {
  color: #fff;
  background-color: #0065b5;
  border-top: 1px solid hsla(0,0%,100%,0.2);
  border-bottom: 1px solid hsla(0,0%,70%,0.1);
}

.uic-toggleBtns .MuiToggleButton-root:hover {
  box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.05);
}
.uic-toggleBtns .MuiToggleButton-root.Mui-selected:hover {
  background-color: #0065b5;
  box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.05);
}
.uic-toggleBtns .Mui-focusVisible {
  box-shadow: 
    0 0 0 1.5px hsla(var(--primary-h), var(--primary-s), calc(var(--primary-l) + 30%), 0.5);
}


/*-----------------------------------------------------------------------
  FileInput
-----------------------------------------------------------------------*/

.uic-fileInput {
  color: unset;
  line-height: unset;
}

.uic-fileInput-label {
  display: block;
  width: 100%;
  height: 100%;
}

.uic-inputFile,
.uic-fileInput-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/*-----------------------------------------------------------------------
  PopoverInput
-----------------------------------------------------------------------*/

.Popover {
  --COLOR-POPOVER: rgb(43, 53, 67);
}
.Popover-body {
  display: inline-flex;
  flex-direction: column;
  padding: 7px;
  background: var(--COLOR-POPOVER);
  color: white;
  border-radius: 0.3rem;
  box-shadow: 0px 1px 10px 1px rgba(15, 15, 15, 0.9);
  border: 1px solid rgba(80, 80, 88, 0.1);
}

.Popover-tip {
  filter: drop-shadow(-2px 2px 2px rgba(12, 12, 12, 0.9));
  stroke: rgba(80, 80, 88, 0.1);
}

.Popover-tipShape {
  fill: var(--COLOR-POPOVER);
}

.Popover-body form {
  white-space: nowrap;
}

.Popover-body .popoverInput-btn {
  padding: 2px;
  margin: -2px 0px 1px 7px;
  min-width: unset;
  width: 26px;
  height: 26px;
}
.Popover-body .popoverInput-btn svg {
  width: 20px;
  height: 20px;
}

.Popover-body .popoverInput-errMsg {
  color: #ff4151;
  padding: 5px 0 0 1px;
  margin-bottom: -2px;
}

.Popover-body .uic-input {
  background: rgb(115, 115, 115);
  padding: 2px 0px 2px 6px;
}
.uic-input.popoverInput-inactive {
  box-shadow: inset 0 0px 4px rgb(28, 28, 31);
  background: rgb(41, 41, 43);
  color: #999;
}

/*-----------------------------------------------------------------------
  Dropdown Menu
-----------------------------------------------------------------------*/

.dropdownMenu-root {
  display: inline-block;
  border: 1px solid rgb(50, 50, 50);
  border-radius: 3px;
  height: 36px;
}

.dropdownMenu-root button.btn {
  padding: 0px 14px;
  /* width: 87px; */
  height: 100%;
  border: none;
  background: rgb(30, 30, 30);
  box-shadow: none;
  border-left: 1px solid rgb(50, 50, 50);
  border-radius: 0px;
}
.dropdownMenu-root button.btn:hover {
  background: rgb(26, 26, 26);
  color: #ccc;
}
.dropdownMenu-root button.btn.active {
  background: var(--primary-dark);
  color: #fff;
  z-index: 2;
}

.dropdownMenu-root button.btn:first-child {
  border-radius: 3px 0px 0px 3px;
  border-left: none;
}
.dropdownMenu-root button.btn:last-child {
  border-radius: 0px 3px 3px 0px;
}

/* ----------- */

.dropdownMenu {
  cursor: default;
  /* z-index: 1; */
}

.dropdownMenu .Popover-body {
  padding: 0px;
  border: none;
  background: none;
}

.dropdownMenu.rootPopover {
  transform: translateX(calc(90px - 87px / 2)) !important;
}

.dropdownMenu ul {
  list-style: none;
  max-height: calc(100vh - 16px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 5px 0px;
  background: rgb(43, 53, 67, 1);
}
@supports (backdrop-filter: blur(5px)) {
  .dropdownMenu ul {
    background: rgba(105, 120, 140, 0.25);
    backdrop-filter: blur(5px);
  }
}

.dropdownMenu ul ul {
  /* 33px - 5px */
  margin-top: -38px;
}
.dropdownMenu.subMenu-popover {
  visibility: hidden;
}

.dropdownMenu li {
  position: static;

  white-space: nowrap;
  padding: 0px 8px;
  min-width: 180px;
  line-height: 32px;
  min-height: 32px;
  text-decoration: none;
  /* background: rgb(43, 53, 67); */
  /* background: rgb(82, 90, 102); */
}

/* isActive = hover */
.dropdownMenu li.isActive,
.dropdownMenu li.selected:hover {
  background: var(--primary-dark);
}
.dropdownMenu li.selected {
  border-left: 2px solid hsl(
    var(--primary-h), 
    calc(var(--primary-s) + 10%),
    calc(var(--primary-l) + 10%)
  );
  font-weight: 500;
  background: hsla(
    var(--primary-h), 
    calc(var(--primary-s) - 20%),
    calc(var(--primary-l) - 5%),
    0.4
  );
}
.dropdownMenu li.selectedBlink {
  animation: selectedBlink 0.11s ease-in-out 1;
}
@keyframes selectedBlink {
  50% {
    background: hsl(
      var(--primary-h), 
      calc(var(--primary-s) - 20%),
      calc(var(--primary-l) - 10%),
      0.2
    );
  }
}
.dropdownMenu li.disabled {
  background: none !important;
  color: #888;
}
.dropdownMenu li.isActive.disabled {
  pointer-events: none;
}

.dropdownMenu li svg {
  margin-top: 9px;
  float: right;
  width: 14px;
  height: 14px;
  color: #ccc;
  /* pointer-events: none; */
}

/*--- borders ---*/
.dropdownMenu ul {
  border: 1px solid rgb(70, 70, 70);
  border-radius: 3px;
}
.dropdownMenu li:not(:last-child) {
  border-bottom: 1px solid rgb(80, 80, 80);
}

/* hover hitbox */
.subMenu-popover .Popover-body::before {
    content: "";
    position: absolute;
    width: 27px;
    left: -27px;
    height: 56px;
    top: -5px;
}

/*-----------------------------------------------------------------------
  Spinner
-----------------------------------------------------------------------*/

.spinner {
  height: 24px;
  width: 24px;
  /* margin: 0 3px; */
  animation: rotation 0.5s infinite linear;
  border-left: 3px solid rgba(0, 174, 239, 0.15);
  border-right: 3px solid rgba(0, 174, 239, 0.15);
  border-bottom: 3px solid rgba(0, 174, 239, 0.15);
  border-top: 3px solid rgba(0, 174, 239, 0.8);
  border-radius: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


/*-----------------------------------------------------------------------
  Button
-----------------------------------------------------------------------*/

.btn {
  --BTN-OPACITY: 0.1;
  display: inline-block;
  /* padding: 4px 10px; */
  padding: 2px 10px 3px 10px;
  min-width: 78px;
  font-size: inherit;
  font-weight: 400;
  /* line-height: 1.2; */
  line-height: 1.42857143;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  cursor: pointer;
  touch-action: manipulation;
  user-select: none;
  color: #9c9c9c;
  border: 1px solid #828282;
  border-radius: 4px;
  text-shadow: 0px 0px 8px #0008, 
               0px 0.4px 1.4px #0009;
  box-shadow: 0px 0.7px 0.7px 1px rgba(0, 0, 0, 0.65),
        inset 0px -3px 6px 0px rgba(0, 0, 0, 0.15),
        inset 0px 2px 5px -2px rgba(255, 255, 255, 0.07);
        
  background: rgba(153, 153, 153, var(--BTN-OPACITY));
  transition: background 0.15s;
}

.btn-thin {
  padding: 1px 8px 2px 8px;
}
.btn-param {
  padding: 1px 8px 2px 8px;
  height: 28px;
  vertical-align: top;
}
.btn-active {
  opacity: 1;
  animation: btnActiveBlink 0.7s ease-in-out infinite;
}
@keyframes btnActiveBlink {
  50% {
    opacity: 0.6;
  }
}

.btn:hover {
  --BTN-OPACITY: 0.05;
  transition: background 0.1s;
}

.btn:active {
  --BTN-OPACITY: 0.28;
  transition: background 0s;
}

.btn:disabled {
  --BTN-OPACITY: 0.06;
  border: 1px solid #666;
  color: #666;
  cursor: default;
  pointer-events: none;
}

.btn-blue {
  background: rgba(5, 169, 246, var(--BTN-OPACITY));
  border: 1px solid var(--primary);
  color: var(--primary);
}

.btn-orange {
  background: rgba(244, 122, 32, var(--BTN-OPACITY));
  border: 1px solid #fa6b19;
  color: #fa6b19;
}

.btn-light-red {
  background: rgba(204, 62, 74, var(--BTN-OPACITY));
  border: 1px solid #cc3e4a;
  color: #cc3e4a;
}

.btn-red {
  background: rgba(204, 62, 74, var(--BTN-OPACITY));
  border: 1px solid #ff0000;
  color: #ff0000;
}

.btn-green {
  background: rgba(132, 181, 71, var(--BTN-OPACITY));
  border: 1px solid #7bc93b;
  color: #7bc93b;
}

.btn .btn-icon {
  vertical-align: middle;
  margin-top: -3px;
  margin-left: -2px;
  margin-right: 2px;
  width: 18px;
  height: auto;
}

.big-btn {
  display: flex;
  padding: 16px 24px;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.42857143;
  width: 100%;
  max-width: 360px;
  border-radius: 8px;
  color: #ddd;
  border: 1px solid rgba(72, 72, 72, 1);
  box-shadow: 0px 0.7px 0.7px 1px rgba(0, 0, 0, 0.65),
        inset 0px -3px 6px 0px rgba(0, 0, 0, 0.1);
}

.big-btn-icon {
  width: 15%;
  height: auto;
  margin: auto;
}

.big-btn-text {
  width: 66%;
  height: auto;
  margin: auto;
  font-size: 24px;
}

.view-meters-text {
  font-size: 32px;
}

svg.view-meters-icon {
  width: 65px;
  height: 65px;
  margin-top: -6px;
  margin-bottom: -14px;
  margin-left: -8px;
}

.locate-unit-icon {
  width: 10%;
  height: 34px;
  transform: translateX(5px);
}

.locate-unit-hoverText {
  --defText: "";
  --hoverText: "";
  padding-right: 8px;
}
.locate-unit-hoverText:before {
  content: var(--defText);
}
.locate-unit-btn.btn-active:hover .locate-unit-hoverText:before {
  content: var(--hoverText);
}


/* solid button */

.uic-btn {
  --h: 0; 
  --s: 0%; 
  --l: 0%;
  font-size: 16px;
  line-height: 1.47;
  letter-spacing: -.01em;
  background: hsl(0, 0%, 27%);
  color: #fff;

  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  min-width: 30px;
  padding: 2px 15px;
  text-align: center;
  white-space: nowrap;
  text-shadow: 0px -0.5px 1px rgba(0,0,0,calc(200% - var(--l)*2.8));
  /* text-shadow: 0px -0.5px 1px rgba(0,0,0,60%); */

  --shading: 0%;
  --clickShading: 0%;
  --h: 0;
  --s: 0%;
  --l: 27%;

  color: #fff;
  background: linear-gradient(
    hsl(
           var(--h),
           var(--s),
      calc(var(--l) - var(--shading))
    ), 
    hsl( 
           var(--h),
           var(--s),
      calc(var(--l) - (var(--l)/4) - var(--shading))
    )
  );

  border: none;
  border-top: none;
  box-shadow:
    0 0 0 1px #0004,
    0 0 0 0.5px #000b,
    /* border */
    inset 0 -0.5px 0 0.5px hsl(
      var(--h),
      var(--s),
      calc(var(--l) - (var(--l)/4) - var(--shading))
    ),
    /* border top */
    inset 0 1.6px 0 -1px hsl(
      var(--h), 
      var(--s),
      calc(var(--l) + (var(--l)/2) - var(--shading) - var(--clickShading))
    );
}

.uic-btn.btn-blue {
  --h: var(--blue-h);
  --s: var(--blue-s);
  --l: var(--blue-l);
  background: linear-gradient(
    hsl(
      var(--h),
      var(--s),
      calc(var(--l) - var(--shading))
    ), 
    hsl( 
      calc(var(--h) + 10),
      calc(var(--s) - 10%),
      calc(var(--l) + 0% - var(--shading))
    )
  );
}
.uic-btn.btn-primary {
  --h: var(--primary-h);
  --s: var(--primary-s);
  --l: var(--primary-l);
}
.uic-btn.btn-orange {
  --h: var(--orange-h);
  --s: calc(var(--orange-s) - 10%);
  --l: calc(var(--orange-l) - 5%);
}
.uic-btn.btn-red {
  --h: 0;
  --s: 68%;
  --l: 50%;
}
.uic-btn.btn-green {
  --h: 135;
  --s: 75%;
  --l: 26%;
}

.uic-btn:hover {
  --shading: calc(var(--l)/10);
}

.uic-btn:active {
  --shading: 6% !important;
  --shading: calc(var(--l)/5) !important;
  --clickShading: calc(var(--l)/2);
}

.uic-btn:disabled {
  --shading: -5%;
  --clickShading: 4%;
  opacity: 0.47;
  cursor: default;
}


button:focus {
  outline: 0;
}
body:not(.intent-mouse) .uic-btn:focus {
  outline: none;
  box-shadow: 
    0 0 0 2px hsla(var(--h), var(--s), calc(var(--l) + 20%), 0.8),
    inset 0 0 0 1px #0006;
}

/*-----------------------------------------------------------------------
  IconButton
-----------------------------------------------------------------------*/

.uic-iconBtn {
  padding: 3px;
  line-height: 1;
  border-color: #445 !important;
  border-radius: 3px;
  min-width: unset;
}
.uic-iconBtn svg {
  font-size: 21px;
  vertical-align: middle;
}
.uic-iconBtn:not(:disabled):hover svg {
  color: #9ce;
}
.MuiTooltip-popper .MuiTooltip-tooltip {
  padding: 2px 6px;
  font-size: 12px;
  font-family: var(--font-family);
  background-color: rgba(70, 70, 70, 0.9);
}

/*-----------------------------------------------------------------------
  Momentary Button
-----------------------------------------------------------------------*/

.btn-momentary-circle,
.btn-momentary-circle * {
  border-radius: 28px !important;
}

.btn-momentary-outer {
  width: 27px;
  height: 27px;
  box-shadow: 0 0px 6px rgb(24, 24, 24);
}

.btn-momentary-outer:hover {
  box-shadow: 0 0px 10px rgb(18, 18, 18);
}

.btn-momentary-btn {
  width: 100%;
  height: 100%;
  padding: 0px;
  box-shadow: inset 0 0 1px 0px rgb(0, 0, 0);
}

/*-----------------------------------------------------------------------
  InfoTooltip
-----------------------------------------------------------------------*/

.uic-infoTooltip-btn {
  vertical-align: text-top;
  margin-left: 4px;
}
.uic-infoTooltip-btn {
  cursor: pointer;
  color: hsl(0, 0%, 60%);
}
.uic-infoTooltip-btn:hover {
  color: hsl(
      var(--primary-h),
      calc(var(--primary-s) - 30%),
      calc(var(--primary-l) + 15%)
    );
}

.uic-infoTooltip.Popover {
  z-index: 1;
}
.uic-infoTooltip .Popover-body {
  padding: 9px;
}

.uic-infoTooltip h1,
.uic-infoTooltip h2,
.uic-infoTooltip h3,
.uic-infoTooltip h4,
.uic-infoTooltip h5,
.uic-infoTooltip h6 { 
  margin-top: 6px;
  margin-bottom: 6px;
  border-bottom: 3px solid #fff2;
  font-weight: 600;
  opacity: 0.8;
}
.uic-infoTooltip h1:first-child,
.uic-infoTooltip h2:first-child,
.uic-infoTooltip h3:first-child,
.uic-infoTooltip h4:first-child,
.uic-infoTooltip h5:first-child,
.uic-infoTooltip h6:first-child {
  margin-top: 0px;
}

.uic-infoTooltip strong { 
  font-weight: bold;
}
.uic-infoTooltip em {
  font-style: italic;
}
.uic-infoTooltip ol,
.uic-infoTooltip ul {
  padding-inline-start: 34px;
}
blockquote {
  color: hsl(35, 6%, 62%);
  border-left-color: hsl(200, 5%, 22%);
  margin: 0;
  padding-left: 3em;
}

/*-----------------------------------------------------------------------
  Table
-----------------------------------------------------------------------*/

.uic-table.tableRowStripes > tbody > tr:nth-child(even) {
  background-color: hsl(0, 0%, 10%, 0.5);
}

.uic-table {
  border-collapse: collapse;

  --rowPadding: 6px 8px;
  --alignLabels: right;
}

.uic-table tr > * {
  padding: var(--rowPadding);
  text-align: left;
  vertical-align: middle;
}

/* label column: */
.uic-table tr > *:first-child {
  text-align: var(--alignLabels);
}

/* value columns: */
.uic-table tr > td:not(:first-child) {
  color: var(--COLOR-TXT-VALUE);
  font-weight: 500;
}

.uic-table th {
  font-size: 16px;
  color: var(--COLOR-GREY-2);
  padding-bottom: 6px;
  opacity: 0.8;
  white-space: nowrap;
}
.uic-table.setLineHeight td {
  line-height: 24px;
}

/*-----------------------------------------------------------------------
  Parameters
-----------------------------------------------------------------------*/

.param-row {
  align-items: center;
}

.param-row-label {
  text-align: right;
  padding-right: 24px;
}

.param-row-label-alt {
  text-align: right;
}

.param-col-header {
  font-size: 18px;
  color: var(--COLOR-GREY-2);
  text-transform: uppercase;
  padding-bottom: 8px;
  white-space: nowrap;
}

/*-----------------------------------------------------------------------
  Text
-----------------------------------------------------------------------*/

.txt-value {
  color: var(--COLOR-TXT-VALUE);
  font-weight: 500;
}

/* .txt-strong {
  text-transform: uppercase;
  font-weight: 500;
} */

.txt-header-sm {
  font-size: 20px;
  font-weight: 500;
  color: var(--COLOR-GREY-2);
  padding-bottom: 8px;
}
.txt-header-md {
  font-size: 24px;
  font-weight: 500;
  color: var(--COLOR-GREY-2);
  padding-bottom: 8px;
}

.txt-info {
  color: #bbb;
  font-weight: 300;
  font-style: italic;
}

/*-----------------------------------------------------------------------
  Snackbar Notification
-----------------------------------------------------------------------*/

.uic-snackbar > div {
  padding: 4px 16px;
  font-size: 18px;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35);
}

.uic-snackbar .uic-snackbar-error {
  background-color: rgba(130, 0, 0, 0.8);
  border: 1px solid rgb(220, 0, 0);
}
.uic-snackbar .uic-snackbar-warning {
  background-color: rgba(201, 137, 0, 0.8);
  border: 1px solid rgb(255, 190, 0);
}
.uic-snackbar .uic-snackbar-info {
  background-color: rgb(0, 85, 210, 0.8);
  border: 1px solid rgb(0, 130, 255);
}
.uic-snackbar .uic-snackbar-success {
  background-color: rgba(14, 105, 16, 0.8);
  border: 1px solid rgb(10, 165, 10);
}

.uic-snackbar-closeBtn {
  font-size: 18px;
  color: rgb(200, 200, 200);
  text-decoration: none !important;
  border: none;
  cursor: pointer;
  background: none;
  margin-left: -8px;
  height: 32px;
  width: 32px;
  border-radius: 100px;
}

.uic-snackbar-closeBtn:hover {
  color: rgb(180, 180, 180);
  background: rgba(0, 0, 0, 0.08);
}

/*-----------------------------------------------------------------------
  Modal
-----------------------------------------------------------------------*/

.uic-modal {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -35%);
  min-width: 360px;
  padding: 18px;
  border-radius: 3px;
  border: 1px solid rgb(64, 64, 64);
  border-top: 1px solid rgb(80, 80, 80);
  background: var(--bg-modal);
  box-shadow: 0px 3px 8px -1px rgba(0, 0, 0, 0.33),
    0px 5px 10px 0px rgba(0, 0, 0, 0.2), 0px 1px 17px 0px rgba(0, 0, 0, 0.4);
}

.uic-modal-content {
  max-width: 600px;
  min-height: 48px;
  max-height: 75vh;
  overflow-y: scroll;
  padding: 0 2px;
  margin: 0 -2px;
  padding-top: 8px;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
}

.uic-modal-content-promptInput {
  width: calc(100% + 8px);
  height: 34px;
  margin-top: 16px;
  margin-bottom: -2px;
}

.uic-modal-btnGroup {
  text-align: right;
  vertical-align: bottom;
  padding-top: 8px;
}
.uic-modal-btnGroup .uic-btn {
  margin-left: 10px;
}

.uic-modal-content b {
  color: var(--COLOR-TXT-VALUE);
  padding: 0px 1px;
}

/* Variants:
*/

.uic-modal-variant {
  --VARIANT-COLOR: rgb(110, 110, 110);
  border: 2px solid var(--VARIANT-COLOR);
  /* border-top: 16px solid var(--VARIANT-COLOR); */
}

.uic-modal-variant svg {
  fill: var(--VARIANT-COLOR);
  vertical-align: bottom;
  width: 31px;
  height: 31px;
  margin-left: 2px;
  margin-right: 12px;
  float: left;
  transform: translateY(-4px);
}

.uic-modal-success {
  --VARIANT-COLOR: rgb(56, 156, 56);
}
.uic-modal-error {
  --VARIANT-COLOR: rgb(200, 60, 60);
}
.uic-modal-warning {
  --VARIANT-COLOR: hsl(35, 100%, 43%);
  /* --VARIANT-COLOR: hsl(25, 90%, 58%); */
}
.uic-modal-info {
  --VARIANT-COLOR: rgb(60, 138, 220);
}

/*-----------------------------------------------------------------------
  Misc
-----------------------------------------------------------------------*/

.uic-indicatorLight {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50px;
}

.uic-hr {
  margin: 16px 0px;
  border-color: #333;
}

.fadein {
  opacity: 1;
  transition: opacity 0.4s;
}
.fadeout {
  opacity: 0;
  transition: opacity 0.4s;
}