/*------------------------- ui-core reset.css ---------------------------
  Base
-----------------------------------------------------------------------*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
/* i, */
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-weight: inherit;
  font-style: inherit;
}

b,
strong {
  font-weight: bolder;
}

/* Remove margins in Firefox and Safari */
input,
button,
select,
optgroup,
textarea {
  margin: 0;
}

/* Change from `box-sizing: content-box` so that `width` 
is not affected by `padding` or `border`. */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

/* Suppress focus outline of elements hidden from tab index.
This prevents an unwanted focus outline from appearing around elements that
might still respond to pointer events. https://github.com/suitcss/base */
[tabindex="-1"]:focus {
  outline: 0 !important;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

audio,
canvas,
video,
progress,
picture {
  display: inline-block;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/* Remove the inheritance of text transform in Firefox */
button,
select {
  text-transform: none;
}


:root {
  --monospace:
    /* macOS 10.10+ */ "Menlo",
    /* Windows 6+ */ "Consolas",
    /* Android 4+ */ "Roboto Mono",
    /* Ubuntu 10.10+ */ "Ubuntu Monospace",
    /* KDE Plasma 5+ */ "Noto Mono",
    /* KDE Plasma 4+ */ "Oxygen Mono",
    /* Linux/OpenOffice fallback */ "Liberation Mono",
    /* fallback */ monospace;
}

code,
kbd,
samp,
pre {
  font-family: var(--monospace);
}